<template>
    <div class="wishList">
        <div class="text-center">
            <List v-if="list&&list.length>0" :list="list"></List>
            <div v-else class="px-3 py-7">
                <p class="text-white mb-4">
                    Not a single deal on your wishlist.<br>
                    Go take a look on open deals right now!
                </p>
                <button class="btn btn-main fw-bold py-2" @click="$router.push('/deal/list/open')">Go to Open Deals</button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import List from '@/components/mydeal/List.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");
export default {
    name:'WishList',
    components:{
        List, Footer
    },
    data(){
        return{
            list:[]
        }
    },
    mounted(){
        this.GetWishList();
    },
    methods:{
        
        GetWishList(){
            
            const body = {};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mydeal/GetWishList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.list = body.list;

                            console.log(this.list)
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        }
    }
}
</script>