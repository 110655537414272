<template>
        <li class="rounded-1 bg-white text-center overflow-hidden item">
            <div class="custom-info p-2 py-3" @click="goTodetail">
                <h6 class="mb-2 fw-bold">{{ item.title }}</h6>
                <div class="coin-info">
                <img :src="require(`@/assets/img/icon/color/${item.coin.toLowerCase()}.svg`)" alt="coin" class="rounded-circle">
                <div class="d-flex flex-column justify-content-between align-items-center">
                    <p><b class="fs-6">{{ item.price.toLocaleString() }}</b> <small>{{item.coin}}</small></p>
                    <small class="text-muted fs-px-12">
                    (= ${{item.total_price}})
                    </small>
                </div>
                </div>
                <p class="fs-px-10 text-muted text-start pb-3">
                Ticket Price: <b>{{item.d_price}} {{item.deal_coin}}(=$ {{(item.d_price * item.deal_price).toLocaleString()}}) / Ticket</b><br>
                Total No. of Ticket: <b>{{item.total}}</b>
                </p>
                <div class="pb-3 w-100" v-if="!own_deal">
                    <span class="ticket bg-burgundy-1 fs-px-12 text-white px-3 py-2 " v-if="item.status==true && item.status_code =='Y'" @click.stop="ClickCheck(item)">Buy Tickets</span>
                    <span class="ticket bg-secondary fs-px-12 text-white px-3 py-2 fw-bold" v-else>CLOSED</span>
                </div>
                <div class="flex-between-center small fs-px-10 mb-1">
                <span>PROGRESS</span>
                <span>{{CheckPercent(item.count,item.total)}}% ({{item.count}}/{{item.total}})</span>
                </div>
                <div class="progress bg-white">
                <div class="progress-bar bg-danger" :style="{width:`${parseInt((item.count/item.total)*100)}%`}"></div>
                </div>
            </div>
            <div class="ticket-info p-2 bg-yellow-5 text-start">
                <div class="flex-between-center">
                <div class="seller fs-px-11 mb-2" @click="$router.push(`/landing/${item.seller_code}`)">
                    Seller<br>
                    <b class="fs-px-12 fw-500">{{item.owner}}</b>
                    <i class="fas fa-check-circle text-burgundy-1 ms-1" v-if="item.type==='A'"></i>
                </div>
                <i class="fa-heart text-burgundy-1 fs-2" :class="{'fas':item.wishList,'far':!item.wishList}" @click="wishList()"></i>
                </div>
            </div>
            <PurchasePopup :show="modalShow" :info="item"  @close="modalClose" v-if="modalShow"/>
        </li>
</template>

<script>
import PurchasePopup from '@/components/deal/PurchaseModal.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
export default {
    components:{
        PurchasePopup
    },
    props:{
        item: {
            type: Object,
            default: function(){
                return{
                    idx: 0,
                    stop_selling:false,
                    userType:'admin',
                    title:'',
                    avatar:'',
                    seller:'',
                    coin:'',
                    price:0,
                    total:0,
                    participate:0,
                    totalNo:0,
                    wishList:false,
                    expiryDate:new Date(),
                    winner:{
                        // router params string
                        status:'false',
                        nickname:'',
                        uid:'',
                        deal_number:'',
                        ticket_quantity:'',
                        result_time:'',
                        ticket_num:''
                    }
                }                
            },
        },
        index: {
            type: Number,
            default: null,
        },
        own_deal: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return{
            modalShow:false,
        }
    },
    methods:{
        ClickCheck(item){
            console.log(item)
            if(item.my_deal =='Y'){
                this.$router.push({path:`/deal/detail/${item.code}`})
            }else{
                this.modalShow = !this.modalShow
            }
        },
        CheckPercent(a,b){
            const count = a;
            const total = b;
            const m = exactMath.div(count,total);
            const per = exactMath.mul(m,100);

            let percent = parseInt(per);

            if(count > 0){
                if(percent <1){
                    percent = 1
                }
            }
            
            return percent;
            

        },
        
        goTodetail(){
            this.$router.push({
                name: 'DealDetail',
                params: {
                    idx: this.item.code,
                    item: this.item
                }
            })
        },
        modalClose() {
        this.modalShow = false;
        },
        wishList(){
            if(this.item.status ==false){
                this.$pp({
                    msg:'This wish list has already been closed.',
                    auto_hide:true
                })
                return false;
            }
            if(this.item.wishList){
                
                this.DeleteWishList()
            }else{
                
                this.AddWishList();
            }
            this.item.wishList=!this.item.wishList;

            
        },
        AddWishList(){
            const room_code = this.item.code;
            const body = {room_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/common/AddWishList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            this.$pp({
                                msg:'Added on wish list',
                                auto_hide:true
                            })
                        }else{
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                        
                    }else{
                        this.$store.dispatch('logout').then(
                            ()=>{
                            location.href = `/signin`;
                            }
                        );
                    }
                }
            );
        },
        DeleteWishList(){
            const room_code = this.item.code;
            const body = {room_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/common/DeleteWishList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            this.$pp({
                                msg:'Removed from wish list',
                                auto_hide:true
                            })
                        }else{
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                        
                    }else{
                        this.$store.dispatch('logout').then(
                            ()=>{
                            location.href = `/signin`;
                            }
                        );
                    }
                }
            )
        }
    },
}
</script>
<style lang="scss" scoped>
 .ticket{
    display: block;
    width: 100%;
    &::before, &::after{
      background-color: #fff;
    }
  }
  .progress{
    height: 20px !important;
  }
</style>