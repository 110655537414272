<template>
    <div>
        <ul class="d-flex px-3 deal-list">
          <Item v-for="(item,index) in list" :key="index" :item="item" :index="index"/>
          <!-- <li class="rounded-3 bg-white text-center overflow-hidden item" v-for="(item, index) in list" :key="index">
            <div class="custom-info p-2 py-3" @click="$router.push(`/deal/detail/${index}`)">
              <h6 class="mb-2 fw-bold">Try today’s deal!</h6>
              <div class="coin-info">
                <img :src="require(`@/assets/img/icon/color/${item.coin}.svg`)" alt="coin" class="rounded-circle">
                <div class="d-flex flex-column justify-content-between align-items-center">
                  <p><b class="fs-6">{{ item.price.toLocaleString() }}</b> <small>BTC</small></p>
                  <small class="text-muted fs-px-12">
                    (= $30,049,488)
                  </small>
                </div>
              </div>
              <p class="fs-px-10 text-muted text-start">
                Ticket Price: <b>1 USDT (=$1.22)</b><br>
                Total No. of Ticket: <b>{{item.totalNo}}</b>
              </p>
              <div class="py-3 w-100">
                <span class="ticket bg-burgundy-1 fs-px-12 text-white px-3 py-2 " v-if="item.status" @click="modalShow = !modalShow">Buy Tickets</span>
                <span class="ticket bg-secondary fs-px-12 text-white px-3 py-2 fw-bold" v-else>CLOSED</span>
              </div>
              <div class="flex-between-center small fs-px-10 mb-1">
                <span>PROGRESS</span>
                <span>{{parseInt((item.participate/item.total)*100)}}% ({{item.participate}}/{{item.total}})</span>
              </div>
              <div class="progress bg-white">
                <div class="progress-bar bg-danger" :style="{width:`${parseInt((item.participate/item.total)*100)}%`}"></div>
              </div>
            </div>
            <div class="ticket-info p-2 bg-yellow-5 text-start">
              <div class="flex-between-center">
                <div class="seller fs-px-12 mb-2">
                  Seller<br>
                  {{item.seller}}
                  <i class="fas fa-check-circle text-burgundy-1"></i>
                </div>
                <i class="fa-heart text-burgundy-1 fs-2" :class="{'fas':item.wishList,'far':!item.wishList}" @click="item.wishList=!item.wishList"></i>
              </div>
            </div>
          </li> -->
        </ul>
        <!-- <PurchasePopup :show="modalShow" @close="modalClose" /> -->
    </div>
</template>
<script>
// import PurchasePopup from '@/components/deal/PurchaseModal.vue'
import Item from '@/components/mydeal/ListItem.vue'

export default {
    name: 'Deal',
    components: {
        // PurchasePopup, 
        Item
    },
    data(){
        return{
            // modalShow:false,
            // ticket_quantity:1,
            // limit:99,
            // step:1,
            // tickets:[
            //     45,46,47,48,49,101,102,103,104,105,106,107,108,109,110,111,112,113,114
            // ],
        }
    },
    computed:{
    },
    props:{
        list:{
            type:Array
        }
    },
    methods:{
        // modalClose() {
        // this.modalShow = false;
        // }
    }
}
</script>
<style lang="scss" scoped>
</style>