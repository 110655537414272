<template>
    <div class="wishList">
        <SubHeader :title="'My Deals'" :link="'/mypage'" right_home_btn />
        <div class="p-3">
            <div class="pt-0 d-flex text-center fw-400">
                <div class="rounded-start py-3 w-50"
                    :class="{'bg-yellow-1 fw-bold': head_path==0 , 'bg-yellow-4 text-gray-500' : head_path !=0}"
                    @click="head_path = 0; $router.push('/mydeal/buy/wish-list')"
                >BUY</div>
                <li class="rounded-end py-3 w-50"
                    :class="{'bg-yellow-1 fw-bold': head_path==1 , 'bg-yellow-4 text-gray-500' : head_path !=1}
                    "
                    @click="head_path = 1; $router.push('/mydeal/sell/sale')"
                >SELL</li>
            </div>
        </div>

        <ul class="flex-around-center px-3 pb-3 gap-3 text-center fs-px-12 sub_tab_list" v-show="head_path==0">
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/buy/wish-list">WishList</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/buy/participate">Participating Deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/buy/won">Won Deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/buy/closed">Closed Deals</router-link>
            </li>
        </ul>

        <ul class="flex-center-center px-3 pb-3 gap-5 text-center fs-px-12 sub_tab_list" v-show="head_path==1">
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/sell/sale">Deal In progress</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/sell/soldout">Closed Deals</router-link>
            </li>
            <li>
                <router-link class="text-yellow-5 py-2 flex-center-center" to="/mydeal/sell/cancel">Cancelled deals</router-link>
            </li>
        </ul>
        <router-view />
        <Footer></Footer>
    </div>
</template>
<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'

export default {
    name:'WishList',
    components:{
        SubHeader, Footer
    },
    data(){
        return{

            // front ui
            head_path: 'buy',
        }
    },
    mounted(){
        this.GetHeadPath();
    },
    watch:{
      $route(to, from) {
        if (to.path != from.path) {
            this.GetHeadPath()
        }
      }
    },
    methods:{
        GetHeadPath(){
            const firstTwoPaths = this.$route.path.substring(1).split('/').slice(0, 2);
            if (firstTwoPaths[1] === 'buy') {
                this.head_path = 0;
            }else if (firstTwoPaths[1] === 'sell'){
                this.head_path = 1;
            }
        }
    }

}
</script>